import { Link } from "gatsby";
import React, { useState } from "react";
import Layout from "../../components/Layout";
import Logo from "../../images/assets/data.svg";
import { gql, useMutation } from "@apollo/client";

const CUSTOMER_RECOVER = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        field
        message
        code
      }
    }
  }
`;

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email,
    };
    customerRecover({
      variables: { email: data.email },
      onCompleted: (result) => {
       alert("Password reset email sent")
      },
    });
  };

   const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  return (
    <Layout>
      <div>
        <section className="bg-gray-50">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
            <Link
              to="/"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
            >
              <Logo width={200} height={200} className="my-8 mx-auto d-block" />
            </Link>
            <div className="w-full p-6 bg-white rounded-lg shadow md:mt-0 sm:max-w-md sm:p-8">
              <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Forgot your password?
              </h1>
              <p className="font-light text-gray-500">
                Don't fret! Just type in your email and we will send you a code
                to reset your password!
              </p>
              <form
                className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="name@domain.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
                >
                  Reset password
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ForgetPassword;
